import { template as template_bf7a51f924d941a99a576288e2f4ab05 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bf7a51f924d941a99a576288e2f4ab05(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
