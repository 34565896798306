import { template as template_688554dea2cd4b3e8a1fa45fdfcf8e02 } from "@ember/template-compiler";
const WelcomeHeader = template_688554dea2cd4b3e8a1fa45fdfcf8e02(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
